<template>
  <div>
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Checking the security token and the availability of certificates therein') }}</h1>
        <p class="has-text-danger">{{ $t('Please, do not remove the security token before the end of operation') }}</p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <div class="media">
                <div class="media-content">
                  <p class="has-text-primary">{{tokenuseText}}: <br><strong>{{devcheckText}}</strong></p>
                  <p class="help help-mod" v-html="TokenUseHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="devCheckSoft" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1)}">
                    {{SoftwareText}}
                  </p>
                  <TokenHelpText v-if="tokenPage" param="step"/>
                  <p class="help help-mod" v-html="SoftwareHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkSoftware" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(23), 'has-text-danger': isTextDanger(23), 'has-text-primary': isTextPrimary(23), 'is-marginless': isTextMarginless(23)}" v-html="BrowserText"></p>
                  <p class="help help-mod" v-html="BrowserHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkBrowser" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}"
                    v-html="PasswordText"></p>
                  <p class="help help-mod" v-html="PasswordHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkPassword" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(3), 'has-text-danger': isTextDanger(3), 'has-text-primary': isTextPrimary(3), 'is-marginless': isTextMarginless(3)}"
                     v-html="KeysText"></p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="checkKeys" width="32">
                </div>
              </div>
              <div class="media" v-if="!deviceIsEmpty">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(4), 'has-text-danger': isTextDanger(4), 'has-text-primary': isTextPrimary(4), 'is-marginless': isTextMarginless(4)}"
                     v-html="CertificatesText"></p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="checkCertificates" width="32">
                </div>
              </div>
              <div class="media" v-if="!deviceIsEmpty && !certificates">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(5), 'has-text-danger': isTextDanger(5), 'has-text-primary': isTextPrimary(5), 'is-marginless': isTextMarginless(5)}"
                     v-html="CertificatesStateText"></p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="checkCertificatesState" width="32">
                </div>
              </div>
              <div class="media" v-if="(!deviceIsEmpty && !certificates && validGOST) || (!deviceIsEmpty && !certificates && validRSA)">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(6), 'has-text-danger': isTextDanger(6), 'has-text-primary': isTextPrimary(6), 'is-marginless': isTextMarginless(6)}">
                    {{SignatureText}}
                  </p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="checkSignature" width="32">
                </div>
              </div>

              <div style="height: 2rem;"></div>

              <div v-if="catchErrorRefresh">
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgainRefresh" v-bind:value="$t('Try again')">
                <div style="height: 5em;"></div>
              </div>
              <div v-if="catchError">
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgain" v-bind:value="$t('Try again')">
                <div style="height: 5em;"></div>
              </div>
              <div v-if="isNext">
                <input class="button is-primary is-medium" type="button" v-on:click="goNext" v-bind:value="$t('Next →')">
                <div style="height: 5em;"></div>
              </div>
              <div v-if="goMain">
                <router-link class="button is-primary is-large is-flex-mobile" to="/">{{ $t('Go back to the main page') }}</router-link>
                <div style="height: 5em;"></div>
              </div>

              <keyProblem  v-if="catchError || catchErrorRefresh"/>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import keyProblem from '../components/KeyProblem'
import TokenHelpText from '../steps/TokenHelpPage'
import { detect } from 'detect-browser'
export default {
  name: 'KeyCheckStepTwo',
  components: {
    keyProblem,
    TokenHelpText
  },
  props: [
    'password',
    'country'
  ],
  data () {
    return {
      allChecked: false,
      textGreyLight: [1, 2, 3, 4, 5],
      textDanger: [],
      textPrimary: [],
      textMarginless: [],
      checkSoftware: require('../assets/icons/check_silver.svg'),
      devCheckSoft:require('../assets/icons/check_silver.svg'),
      SoftwareText: this.$i18n.t('Checking whether the software necessary for security token operation is installed').toString(),
      SoftwareHelpText: null,
      TokenUseHelpText: null,
      devcheckText: null,
      tokenuseText: this.$i18n.t('Token you use').toString(),
      checkPassword: require('../assets/icons/check_silver.svg'),
      PasswordText: this.$i18n.t('Checking access to the security token by the entered password').toString(),
      PasswordHelpText: null,
      checkKeys: require('../assets/icons/check_silver.svg'),
      KeysText: this.$i18n.t('Checking the existence of EDS keys').toString(),
      checkCertificates: require('../assets/icons/check_silver.svg'),
      CertificatesText: this.$i18n.t('Checking the existence of certificates').toString(),
      checkCertificatesState: require('../assets/icons/check_silver.svg'),
      CertificatesStateText: this.$i18n.t('Checking the status and validity of certificates').toString(),
      checkSignature: require('../assets/icons/check_silver.svg'),
      SignatureText: this.$i18n.t('Checking the possibility of staging the digital signature').toString(),
      keyExGOST: false,
      keyExRSA: false,
      certExGOST: false,
      certExRSA: false,
      validGOST: false,
      validRSA: false,
      objName: false,
      certGOST: false,
      certRSA: false,
      certificateInfoGOST: null,
      certificateInfoRSA: null,
      data: null,
      devices: null,
      catchError: false,
      catchErrorRefresh: false,
      objectsGOST: null,
      objectsRSA: null,
      deviceIsEmpty: false,
      certificates: false,
      isNext: false,
      tokenPage: null,
      goMain: null,
      RSASupport: null,
      BrowserTextVal: null,
      BrowserHelpText: null,
      checkBrowser: require('../assets/icons/check_silver.svg'),
    }
  },
  watch: {
    allChecked: {
      handler: function (val) {
        if (!val) {
          this.checkAll()
        }
      },
      immediate: true
    }
  },
  computed: {
    isSocket () {
      return this.$store.state.socket
    },
    isSocketKey () {
      return this.$store.state.socketKey
    },
    enabledDevs () {
      return this.country
    },
    lang () {
      return this.$i18n.locale
    },
    browser () {
      return detect()
    },
    BrowserText () {
      if (this.BrowserTextVal === '1') {
        return this.$i18n.t('Your browser is successfully defined').toString()
      } else if (this.BrowserTextVal === '2') {
        return this.$i18n.t('Your browser is not supported').toString()
      } else {
        return this.$i18n.t('Checking your browser').toString()
      }
    },
  },
  methods: {
    isTextGreyLight (id) {
      return this.textGreyLight.find(text => text === id)
    },
    isTextDanger (id) {
      return this.textDanger.find(text => text === id)
    },
    isTextPrimary (id) {
      return this.textPrimary.find(text => text === id)
    },
    isTextMarginless (id) {
      return this.textMarginless.find(text => text === id)
    },
    TryAgainRefresh () {
      location.reload()
    },
    TryAgain () {
      this.goMain = false
      this.catchError = false
      this.allChecked = false
      this.textGreyLight = [1, 2, 3, 4, 5]
      this.textDanger = []
      this.textPrimary = []
      this.textMarginless = []
      this.checkSoftware = require('../assets/icons/check_silver.svg')
      this.devCheckSoft = require('../assets/icons/check_silver.svg')
      this.SoftwareText = this.$i18n.t('Checking whether the software necessary for security token operation is installed').toString()
      this.SoftwareHelpText = null
      this.checkPassword = require('../assets/icons/check_silver.svg')
      this.tokenuseText = this.$i18n.t('Token you use').toString()
      this.devcheckText = require('../assets/icons/loading.svg')
      this.PasswordText = this.$i18n.t('Checking access to the security token by the entered password').toString()
      this.PasswordHelpText = null
      this.checkKeys = require('../assets/icons/check_silver.svg')
      this.KeysText = this.$i18n.t('Checking the existence of EDS keys').toString()
      this.checkCertificates = require('../assets/icons/check_silver.svg')
      this.CertificatesText = this.$i18n.t('Checking the existence of certificates').toString()
      this.checkCertificatesState = require('../assets/icons/check_silver.svg')
      this.CertificatesStateText = this.$i18n.t('Checking the status and validity of certificates').toString()
      this.checkSignature = require('../assets/icons/check_silver.svg')
      this.SignatureText = this.$i18n.t('Checking the possibility of staging the digital signature').toString()
      this.checkAll()
    },
    PromiseCheckSocketState () {
      return new Promise((resolve, reject) => {
        if (this.isSocket) {
          if (this.isSocketKey) {
            resolve()
          } else {
            this.catchErrorRefresh = true
            this.textDanger = [1]
            this.textMarginless = [1]
            this.SoftwareText = this.$i18n.t('Please install the correct version of the ”CryptoSocket” software').toString()
            this.checkSoftware = require('../assets/icons/error.svg')
            reject(this.SoftwareText)
          }
        } else {
          this.catchErrorRefresh = true
          this.textDanger = [1]
          this.textMarginless = [1]
          this.SoftwareText = this.$i18n.t('Please make sure the ”CryptoSocket” software is running').toString()
          this.SoftwareHelpText = this.$i18n.t('Please install the software')
          this.checkSoftware = require('../assets/icons/error.svg')
          reject(this.SoftwareText)
        }
      })
    },
    PromiseCheckToken () {
      return new Promise((resolve, reject) => {
        this.devices = this.devices.devices
        this.reader = this.devices[0].reader
        console.log('reader=' + this.reader)
        console.log('readerName=' + this.devices[0].name)
        let error = null
        if (this.devices.length > 2) {
          error = this.$i18n.t('Found more than one security token').toString()
          this.SoftwareHelpText = this.$i18n.t('Make sure, that only one security token is connected to the PC and try the operation again').toString()
        }
        if (this.devices[0].name === 'KzToken_not_supported' || this.reader.indexOf('Rutoken') >= 0) {
          error = this.$i18n.t('The security token is not supported').toString()
        }
        if (this.enabledDevs) {
          console.log('enabledDevs=' + this.enabledDevs)
          for (let i = 0; i < this.devices.length; i++) {
            if (this.devices[i].algorithm.indexOf('GOST') >= 0) {
              if (this.enabledDevs.indexOf(',')) {
                let re = /\s*,\s*/
                let res = this.enabledDevs.split(re)
                for (let j = 0; j < res.length; j++) {
                  //                  if (this.devices[i].name.indexOf(res[j]) >= 0) {
                  if (this.devices[i].name === res[j]) {
                    error = this.$i18n.t('Working with this security token is not supported').toString() // запрещено в кабинете
                  }
                }
              } else {
                if (this.devices[i].name === this.enabledDevs) {
                  //                if (this.devices[i].name.indexOf(this.enabledDevs) >= 0) {
                  error = this.$i18n.t('Working with this security token is not supported').toString() // запрещено в кабинете
                }
              }
            }
          }
        }
        if (error) {
          this.catchError = true
          this.SoftwareText = error
          this.textDanger = [1]
          this.textMarginless = [1]
          this.checkSoftware = require('../assets/icons/error.svg')
          reject(error)
        } else {
          resolve()
        }
      })
    },

    devcheck () {
      var devch = this.devices[0].name;
      if(devch == "AladdinJaCarta2") this.devcheckText = this.$i18n.t('JaCarta-2 PKI/ГОСТ').toString(); else
      if(devch == "AladdinJaCartaGAMMA") this.devcheckText = this.$i18n.t("JaCarta PKI/ГОСТ").toString(); else
      if(devch == "SafeNet 5110 with applet(GemCart)") this.devcheckText = this.$i18n.t("SafeNet eToken 5110 (GC)").toString();else
      if(devch == "SafeNetToken") this.devcheckText = this.$i18n.t("SafeNet eToken 5110").toString();else
      if(devch == "JaCarta2(Software)") this.devcheckText = this.$i18n.t("JaCarta-2 PKI/ГОСТ").toString();
    },
    PromiseCheckSoftware () {
      return new Promise((resolve, reject) => {
        const vm = this
        setTimeout(() => {
          for (let i = 0; i < vm.devices.length; i++) {
            if (vm.devices[i].algorithm === 'RSA') {
              vm.RSASupport = true
            }
          }
          if (!vm.RSASupport) {
            if (vm.devices[0].name.indexOf('SafeNet 5110') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install “SafeNet Authentication Client” software').toString()
            } else if (vm.devices[0].name.indexOf('JavaToken') >= 0 || vm.devices[0].name.indexOf('SafeNet Token') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for eToken PRO 72k (Java)').toString()
            } else if (vm.devices[0].name.indexOf('JaCarta') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for JaCarta') +
                      ' - <a href="http://aladdin-rd.ru/support/downloads/43987/">' + this.$i18n.t('”JaCarta Unified Client” software').toString() + '</a>.'
            } else if (vm.devices[0].name.indexOf('KzToken') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install the appropriate KzToken software').toString()
            }
            this.catchError = true
            vm.textDanger = [1]
            vm.textMarginless = [1]
            vm.SoftwareText = this.$i18n.t('The security token software is not detected').toString()
            vm.checkSoftware = require('../assets/icons/error.svg')
            reject(vm.SoftwareText)
          } else {
            vm.SoftwareText = this.$i18n.t('The security token software is detected').toString()
            resolve()
          }
        }, 2000)
      })
    },

    checkBrowserOk () {
      this.textPrimary = [1, 23]
      this.textGreyLight = [2]
      this.BrowserTextVal = '1'
      this.checkBrowser = require('../assets/icons/check_green.svg')
      // this.checkSoftware = require('../assets/icons/loading.svg')
    },
    checkBrowserBad () {
      this.catchError = true
      this.textDanger = [23]
      this.textMarginless = [23]
      this.BrowserTextVal = '2'
      this.BrowserHelpText = this.$i18n.t('Supported browsers').toString()
      console.log(this.$i18n + "====> current locale")
      this.checkBrowser = require('../assets/icons/error.svg')
    },
    PromiseCheckBrowser () {
      return new Promise((resolve, reject) => {
        this.checkBrowser = require('../assets/icons/loading.svg')
        this.textGreyLight = [2,3,4,5,6]
        setTimeout(() => {
          if (this.browser) {
            console.log(this.browser.name)
            console.log(this.browser.version)
            if (this.browser.name === 'chrome') {
              if (parseInt(this.browser.version) >= 49) {
                this.checkBrowserOk()
                resolve(this.checkBrowser)
              } else {
                this.checkBrowserBad()
                reject(this.BrowserText)
              }
            } else if (this.browser.name === 'firefox') {
              if (parseInt(this.browser.version) >= 61) {
                this.checkBrowserOk()
                resolve(this.checkBrowser)
              } else {
                this.checkBrowserBad()
                reject(this.BrowserText)
              }
            } else if (this.browser.name === 'ie') {
              this.checkBrowserBad()
              reject(this.BrowserText)
            } else if (this.browser.name === 'edge') {
              this.checkBrowserOk()
              resolve(this.checkBrowser)
            } else if (this.browser.name === 'opera') {
              if (parseInt(this.browser.version) >= 55) {
                this.checkBrowserOk()
                resolve(this.checkBrowser)
              } else {
                this.checkBrowserBad()
                reject(this.BrowserText)
              }
            } else {
              this.checkBrowserBad()
              reject(this.BrowserText)
            }
          }
        }, 2000)
      })
    },
    async getObjectsGOST () {
      this.objectsGOST = JSON.parse(this.objectsGOST.objects)
      this.objectsGOST = this.objectsGOST.objects
      let certObjGOST = null
      if (this.objectsGOST) {
        for (let i = 0; i < this.objectsGOST.length; i++) {
          if ((this.objectsGOST[i].name).indexOf('G') === 0) { // наш ключ
            this.keyExGOST = true
            this.objName = this.objectsGOST[i].name
            if (this.objectsGOST[i].cert_b64) {
              certObjGOST = this.objectsGOST[i].cert_b64
              this.certExGOST = true
              break
            }
          }
        }
        if (certObjGOST) {
          this.certificateInfoGOST = await this.cryptoSocket.getCertificateInfo({ id: 3, certificate: certObjGOST })
          if (this.certificateInfoGOST) {
            let endDate = new Date(this.certificateInfoGOST.validTo.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')) // GMT format
            let today = new Date().toUTCString()
            let today1 = new Date(today.replace(/GMT.*/g, '')) // GMT format

            console.log('endDate = ' + endDate)
            console.log('today = ' + today)
            console.log('today1 = ' + today1)

            if (endDate > today1) {
              this.validGOST = true
            }
            console.log('validGOST = ' + this.validGOST)
            this.certGOST = this.certificateInfoGOST
          }
        }
      }
    },
    async getObjectsRSA () {
      this.objectsRSA = JSON.parse(this.objectsRSA.objects)
      this.objectsRSA = this.objectsRSA.objects
      let certObjRSA = null
      if (this.objectsRSA) {
        for (let i = 0; i < this.objectsRSA.length; i++) {
          if ((this.objectsRSA[i].name).indexOf('G') === 0) { // наш ключ
            this.keyExRSA = true
            this.objName = this.objectsRSA[i].name
            if (this.objectsRSA[i].cert_b64) {
              certObjRSA = this.objectsRSA[i].cert_b64
              this.certExRSA = true
              break
            }
          }
        }
        if (certObjRSA) {
          this.certificateInfoRSA = await this.cryptoSocket.getCertificateInfo({ id: 4, certificate: certObjRSA })
          if (this.certificateInfoRSA) {
            let endDate = new Date(this.certificateInfoRSA.validTo.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')) // GMT format
            let today = new Date().toUTCString()
            let today1 = new Date(today.replace(/GMT.*/g, '')) // GMT format
            if (endDate > today1) {
              this.validRSA = true
            }
            console.log('validRSA = ' + this.validRSA)
            this.certRSA = this.certificateInfoRSA
          }
        }
      }
    },
    PromiseCheckObjects () {
      return new Promise((resolve, reject) => {
        const vm = this
        setTimeout(() => {
          let error = ''
          let cert = false
          if (vm.keyExGOST || vm.keyExRSA) {
            cert = true
          }
          if (!vm.objectsGOST.length && !vm.objectsRSA.length) {
            error = 'deviceIsEmpty'
          } else {
            if (!vm.keyExGOST) {
              error += this.$i18n.t('The device does not contain the key algorithm GOST').toString() + '<br>' + this.$i18n.t('The device contains the key algorithm RSA matching the order', [this.objName]).toString()
            }
            if (!vm.keyExRSA) {
              error += this.$i18n.t('The device does not contain the key algorithm RSA').toString() + '<br>' + this.$i18n.t('The device contains the key algorithm GOST matching the order', [this.objName]).toString()
            }
          }
          if (error) {
            if (!cert) {
              this.certificates = true
              if (error === 'deviceIsEmpty') {
                this.goMain = true
                this.KeysText = this.$i18n.t('The security token is supported').toString()
                this.textPrimary = [1, 2, 3]
                this.checkKeys = require('../assets/icons/check_green.svg')
              } else {
                this.textDanger = [3]
                this.textMarginless = [3]
                this.KeysText = this.$i18n.t('The security token is supported').toString() + '. <br>' + error
                this.checkKeys = require('../assets/icons/error.svg')
              }
              this.deviceIsEmpty = true
              reject(this.KeysText)
            } else {
              this.KeysText = error
              this.textDanger = [3]
              this.textMarginless = [3]
              this.checkKeys = require('../assets/icons/error.svg')
              resolve(this.KeysText)
            }
          } else {
            this.KeysText = this.$i18n.t('The existence of EDS keys is checked').toString() + '<br>' + this.$i18n.t('Keys match the order', [this.objName]).toString()
            this.textPrimary = [1, 2, 3]
            this.checkKeys = require('../assets/icons/check_green.svg')
            resolve(this.KeysText)
          }
        }, 2000)
      })
    },
    PromiseCheckCertificates () {
      return new Promise((resolve, reject) => {
        const vm = this
        let error = null
        setTimeout(() => {
          if (!vm.certExGOST && !vm.certExRSA) {
            error = 'CertsNotFound'
          } else if (!vm.certExGOST) {
            error = this.$i18n.t('The device does not contain the certificate GOST algorithm').toString()
          } else if (!vm.certExRSA) {
            error = this.$i18n.t('The device does not contain the certificate RSA algorithm').toString()
          }
          if (error) {
            if (error === 'CertsNotFound') {
              this.goMain = true
              this.textPrimary = [1, 2, 3, 4]
              this.CertificatesText = this.$i18n.t('The security token is supported. The device does not contain the certificate GOST algorithm. The device does not contain the certificate RSA algorithm').toString()
              this.checkCertificates = require('../assets/icons/check_green.svg')
            } else {
              this.CertificatesText = this.$i18n.t('The security token is supported').toString() + ' <br>' + error
              this.textDanger = [4]
              this.textMarginless = [4]
              this.checkCertificates = require('../assets/icons/error.svg')
              this.isNext = true
              // this.certificates = true
            }
            this.certificates = true
            reject(error)
          } else {
            this.CertificatesText = this.$i18n.t('The existence of certificates is checked').toString()
            resolve(this.CertificatesText)
          }
        }, 2000)
      })
    },
    PromiseCheckValid () {
      return new Promise((resolve, reject) => {
        const vm = this
        let error = ''
        setTimeout(() => {
          if (!vm.validGOST) {
            error += this.$i18n.t('Algorithm GOST certificate is expired').toString()
          }
          if (!vm.validRSA) {
            error += '<br>' + this.$i18n.t('Algorithm RSA certificate is expired').toString()
          }
          if (error) {
            this.CertificatesStateText = error
            this.textDanger = [5]
            this.textMarginless = [5]
            this.checkCertificatesState = require('../assets/icons/error.svg')
            this.isNext = true
            reject(error)
          } else {
            this.CertificatesStateText = this.$i18n.t('The status and validity of certificates are checked').toString()
            resolve()
          }
        }, 2000)
      })
    },
    devCheckSoftOne(){
      return require('../assets/icons/loading.svg')
    },
    devCheckSoftEnd(){
      return require('../assets/icons/check_green.svg')
    },
    startStepOne () {
      this.textGreyLight = [2, 23, 3, 4, 5, 6]
      return require('../assets/icons/loading.svg')
    },
    endStepOne () {
      this.textPrimary = [1]
      return require('../assets/icons/check_green.svg')
    },
    startStepTwo () {
      this.textGreyLight = [3, 4, 5, 6]
      return require('../assets/icons/loading.svg')
    },
    endStepTwo () {
      this.PasswordText = this.$i18n.t('Access to the security token by the entered password is checked').toString()
      this.textPrimary = [1, 23, 2]
      return require('../assets/icons/check_green.svg')
    },
    stepThree () {
      this.textGreyLight = [4, 5, 6]
      return require('../assets/icons/loading.svg')
    },
    startStepFour () {
      this.textGreyLight = [5, 6]
      return require('../assets/icons/loading.svg')
    },
    endStepFour () {
      this.textPrimary = [1, 23, 2, 3, 4]
      return require('../assets/icons/check_green.svg')
    },
    startStepFive () {
      this.textGreyLight = [6]
      return require('../assets/icons/loading.svg')
    },
    endStepFive () {
      this.textPrimary = [1, 23, 2, 3, 4, 5]
      return require('../assets/icons/check_green.svg')
    },
    startStepSix () {
      this.textGreyLight = []
      return require('../assets/icons/loading.svg')
    },
    endStepSix () {
      this.SignatureText = this.$i18n.t('The possibility of staging the digital signature is checked').toString()
      this.textPrimary = [1, 23, 2, 3, 4, 5, 6]
      return require('../assets/icons/check_green.svg')
    },
    goNext () {
      return new Promise(resolve => {
        this.$emit('update:order', this.objName)
        this.$emit('update:devcheck', this.devices[0].name)
        this.$emit('update:certGOST', this.certGOST)
        this.$emit('update:certRSA', this.certRSA)
        const vm = this
        setTimeout(() => {
          vm.$emit('update:currentForm', 'FormStepFour')
          resolve(true)
        }, 1000)
      })
    },
    async checkAll () {
      try {
        this.devCheckSoft = await  this.devCheckSoftOne()
        this.checkSoftware = await this.startStepOne()
        await this.PromiseCheckSocketState()
        this.devices = await this.cryptoSocket.getDevices({ id: 8 })
        console.log( this.devices )
        await this.PromiseCheckToken()
        await this.PromiseCheckSoftware()
        await this.devcheck()
        this.devCheckSoft = await this.devCheckSoftEnd()
        this.checkSoftware = await this.endStepOne()
        this.checkBrowser = await this.PromiseCheckBrowser()
        this.checkPassword = await this.startStepTwo()
        await this.cryptoSocket.CheckPassword({ id: 7, reader: this.devices[0].reader, pass: this.password, alg: 'GOST' })
        await this.cryptoSocket.CheckPassword({ id: 8, reader: this.devices[0].reader, pass: this.password, alg: 'RSA' })
        this.checkPassword = await this.endStepTwo()
        this.checkKeys = await this.stepThree()
        this.objectsGOST = await this.cryptoSocket.getObjects({ id: 7, reader: this.devices[0].reader, pass: this.password, alg: 'GOST' })
        this.objectsRSA = await this.cryptoSocket.getObjects({ id: 8, reader: this.devices[0].reader, pass: this.password, alg: 'RSA' })
        await this.getObjectsGOST()
        await this.getObjectsRSA()
        await this.PromiseCheckObjects()
        this.checkCertificates = this.startStepFour()
        await this.PromiseCheckCertificates()
        this.checkCertificates = this.endStepFour()
        this.checkCertificatesState = this.startStepFive()
        await this.PromiseCheckValid()
        this.checkCertificatesState = this.endStepFive()
        this.checkSignature = this.startStepSix()
        this.devices[0].reader.indexOf('KAZTOKEN') > -1
          ? (this.data = await this.cryptoSocket.createPkcs7({ id: 1, reader: this.devices[0].reader, object: this.objName, pass: this.password, alg: 'GOST', dataToSign: 'SGVsbG8gV29ybGQhISENCg0K', isAttach: false })) : null
        this.data ? this.cryptoSocket.verifyPkcs7({ id: 1, reader: this.devices[0].reader, alg: 'GOST', data: 'SGVsbG8gV29ybGQhISENCg0K', pkcs7: this.data.sign, isString: 1 }) : null
        this.checkSignature = this.endStepSix()
        this.allChecked = await this.goNext()
      } catch (e) {
        if (e.Function === 'getDevices') {
          this.textDanger = [1]
          this.textMarginless = [1]
          this.checkSoftware = require('../assets/icons/error.svg')
          this.SoftwareText = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
        if (e.Function === 'CheckPassword') {
          this.catchErrorRefresh = true
          this.textDanger = [2]
          this.textMarginless = [2]
          this.checkPassword = require('../assets/icons/error.svg')
          this.PasswordText = this.$i18n.t('Security token access error, maybe the password is invalid').toString()
          this.PasswordHelpText = this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString()
        }
      }
    }
  }
}
</script>

<style scoped>
  .help-mod {
    width: 625px;
  }
</style>

<template>
  <div>
    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Checking the security token and the entered password') }}</h1>
        <p class="has-text-grey-light">{{ $t('Please, do not remove the security token before the end of operation') }}</p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1)}">
                    {{SoftwareText}}
                  </p>
                  <TokenHelpText v-if="tokenPage" param="step"/>
                  <p class="help help-mod" v-html="SoftwareHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkSoftware" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}"
                    v-html="PasswordText"></p>
                  <p class="help help-mod" v-html="PasswordHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="checkPassword" width="32">
                </div>
              </div>

              <div style="height: 2rem;"></div>

              <div v-if="catchErrorRefresh">
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgainRefresh" v-bind:value="$t('Try again')">
                <div style="height: 3em;"></div>
              </div>

              <div v-if="allChecked">
                <router-link class="button is-primary is-large is-flex-mobile" to="/">{{ $t('Go back to the main page') }}</router-link>
                <div style="height: 3em;"></div>
              </div>

              <keyProblem v-if="catchErrorRefresh"/>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import keyProblem from '../components/KeyProblem'
import TokenHelpText from '../steps/TokenHelpPage'
export default {
  name: 'KeyCheckStepThree',
  components: {
    keyProblem,
    TokenHelpText
  },
  props: [
    'password'
  ],
  data () {
    return {
      orderModalActive: false,
      allChecked: false,
      newpassword:null,
      textGreyLight: [1, 2, 3, 4, 5],
      textDanger: [],
      textPrimary: [],
      textMarginless: [],
      checkSoftware: require('../assets/icons/check_silver.svg'),
      SoftwareText: this.$i18n.t('Checking your security token').toString(),
      SoftwareHelpText: null,
      checkPassword: require('../assets/icons/check_silver.svg'),
      PasswordText: this.$i18n.t('Checking access to the security token by the entered password').toString(),
      PasswordHelpText: null,
      devices: null,
      catchErrorRefresh: false,
      tokenPage: null
    }
  },
  watch: {
    allChecked: {
      handler: function (val) {
        if (!val) {
          this.checkAll()
        }
      },
      immediate: true
    }
  },
  computed: {
    isSocket () {
      return this.$store.state.socket
    },
    isSocketKey () {
      return this.$store.state.socketKey
    },
    lang () {
      return this.$i18n.locale
    }
  },
  methods: {
    openOrderModal () {
      this.orderModalActive = !this.orderModalActive
    },
    isTextGreyLight (id) {
      return this.textGreyLight.find(text => text === id)
    },
    isTextDanger (id) {
      return this.textDanger.find(text => text === id)
    },
    isTextPrimary (id) {
      return this.textPrimary.find(text => text === id)
    },
    isTextMarginless (id) {
      return this.textMarginless.find(text => text === id)
    },
    TryAgainRefresh () {
      location.reload()
    },
    PromiseCheckSocketState () {
      return new Promise((resolve, reject) => {
        if (this.isSocket) {
          if (this.isSocketKey) {
            resolve()
          } else {
            this.catchErrorRefresh = true
            this.textDanger = [1]
            this.textMarginless = [1]
            this.SoftwareText = this.$i18n.t('Please install the correct version of the ”CryptoSocket” software').toString()
            this.checkSoftware = require('../assets/icons/error.svg')
            reject(this.SoftwareText)
          }
        } else {
          this.catchErrorRefresh = true
          this.textDanger = [1]
          this.textMarginless = [1]
          this.SoftwareText = this.$i18n.t('Please make sure the ”CryptoSocket” software is running').toString()
          this.SoftwareHelpText = this.$i18n.t('Please install the software') + '<a href="/application/distr/SetupCryptoSocket_' + this.lang + '.zip?v=1">CryptoSocket</a>' + this.$i18n.t('if it is not installed on your PC')
          this.checkSoftware = require('../assets/icons/error.svg')
          reject(this.SoftwareText)
        }
      })
    },
    PromiseCheckToken () {
      return new Promise((resolve, reject) => {
        this.devices = this.devices.devices
        let error = null
        if (this.devices.length > 2) {
          error = this.$i18n.t('Found more than one security token').toString()
          this.SoftwareHelpText = this.$i18n.t('Make sure, that only one security token is connected to the PC and try the operation again').toString()
        }
        if (error) {
          this.catchError = true
          this.SoftwareText = error
          this.textDanger = [1]
          this.textMarginless = [1]
          this.checkSoftware = require('../assets/icons/error.svg')
          reject(error)
        } else {
          this.SoftwareText = this.$i18n.t('The security token is supported').toString()
          resolve()
        }
      })
    },
    startStepOne () {
      this.textGreyLight = [2, 3, 4, 5, 6]
      return require('../assets/icons/loading.svg')
    },
    endStepOne () {
      this.textPrimary = [1]
      return require('../assets/icons/check_green.svg')
    },
    startStepTwo () {
      this.textGreyLight = [3, 4, 5, 6]
      return require('../assets/icons/loading.svg')
    },
    endStepTwo () {
      this.PasswordText = this.$i18n.t('The password is correct').toString()
      this.textPrimary = [1, 2]
      return require('../assets/icons/check_green.svg')
    },
    async checkAll () {
      try {
        this.checkSoftware = await this.startStepOne()
        await this.PromiseCheckSocketState()
        this.devices = await this.cryptoSocket.getDevices({ id: 8 })
        await this.PromiseCheckToken()
        this.checkSoftware = await this.endStepOne()
        this.checkPassword = await this.startStepTwo()
        await this.cryptoSocket.CheckPassword({ id: 7, reader: this.devices[0].reader, pass: this.password, alg: 'GOST' })
        await this.cryptoSocket.CheckPassword({ id: 8, reader: this.devices[0].reader, pass: this.password, alg: 'RSA' })
        console.log(this.devices[0].reader);
        this.checkPassword = await this.endStepTwo()
        this.allChecked = true
      } catch (e) {
        if (e.Function === 'getDevices') {
          this.textDanger = [1]
          this.textMarginless = [1]
          this.checkSoftware = require('../assets/icons/error.svg')
          this.SoftwareText = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
        if (e.Function === 'CheckPassword') {
          this.catchErrorRefresh = true
          this.textDanger = [2]
          this.textMarginless = [2]
          this.checkPassword = require('../assets/icons/error.svg')
          this.PasswordText = this.$i18n.t('Security token access error, maybe the password is invalid').toString()
          this.PasswordHelpText = this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString()
          // this.openOrderModal();
        }
        if (e.Function === 'changePassword') {
          console.log();
        }
      }
    }
  }
}
</script>

<style scoped>
  .help-mod {
    width: 625px;
  }
</style>
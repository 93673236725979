<template>
  <div>
    <KeyCheckStepOne
      v-if="currentForm === 'FormStepOne'"
      v-bind:current-form.sync="currentForm"
      v-bind:password.sync="password"
      v-bind:country.sync="country"
    />
    <KeyCheckStepTwo
      v-if="currentForm === 'FormStepTwo'"
      v-bind:current-form.sync="currentForm"
      v-bind:password.sync="password"
      v-bind:country.sync="country"
      v-bind:order.sync="order"
      v-bind:cert-GOST.sync="certGOST"
      v-bind:cert-RSA.sync="certRSA"
      v-bind:devcheck.sync="devcheck"
    />
    <KeyCheckStepThree
      v-if="currentForm === 'FormStepThree'"
      v-bind:current-form.sync="currentForm"
      v-bind:password.sync="password"
    />
    <KeyCheckResult
      v-if="currentForm === 'FormStepFour'"
      v-bind:current-form.sync="currentForm"
      v-bind:params="params"
    />
  </div>
</template>

<script>
import KeyCheckStepOne from '../pages/KeyCheckStepOne'
import KeyCheckStepTwo from '../pages/KeyCheckStepTwo'
import KeyCheckStepThree from '../pages/KeyCheckStepThree'
import KeyCheckResult from '../pages/KeyCheckResult'
export default {
  name: 'KeyCheck',
  components: {
    KeyCheckStepOne,
    KeyCheckStepTwo,
    KeyCheckStepThree,
    KeyCheckResult
  },
  data () {
    return {
      currentForm: 'FormStepOne',
      password: null,
      country: null,
      order: null,
      certGOST: null,
      certRSA: null,
      revoked: false,
      devcheck: null
    }
  },
  watch: {
    order () {
      if (this.order) {
        this.$store.commit('updateKeyCheckOrder', this.order)
        this.$http.get('/query/get-order-info/', { params: { order: this.order, type: 'key_check' } }).then((response) => {
          if (response.data[0].status == 4) {
            this.revoked = true
          }
        })
      }
    }
  },
  computed: {
    params () {
      return {
        revoked: this.revoked,
        order: this.order,
        dnGOST: this.certGOST.subjectName,
        dnRSA: this.certRSA.subjectName,
        validFromGOST: this.certGOST.validFrom,
        validFromRSA: this.certRSA.validFrom,
        validToGOST: this.certGOST.validTo,
        validToRSA: this.certRSA.validTo,
        devcheck: this.devcheck,
      }
    }
  },
  mounted () {
    this.startSocket()
  }
}
</script>

<template>
  <div>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Security token checking results') }}</h1>
        <p class="has-text-grey-light">{{ $t('You can remove the security token') }}</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-7">
              <div style="height: 2rem;"></div>
              <article class="message is-primary is-small">
                <div class="message-header">
                  <p>{{ $t('Certificate Information') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <table v-if="dnGOST">
                    <tbody>
                    <tr>
                      <td class="is-9" colspan="2">
                        <strong>{{ $t('Certificate for Signing') }}</strong>
                        <span v-if="revoked"> | <strong>{{$t('status')}}</strong>: <span class="has-text-danger">{{$t('revoked')}}</span></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="is-3">{{ $t('Order number') }}</td>
                      <td class="is-9">{{order}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('DN-name') }}</td>
                      <td>{{dnGOST}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Validity') }}</td>
                      <td>{{validFromGOST}} - {{validToGOST}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table v-if="dnRSA">
                    <tbody>
                    <tr>
                      <td class="is-9" colspan="2">
                        <strong>{{ $t('Certificate for Encryption') }}</strong>
                        <span v-if="revoked"> | <strong>{{$t('status')}}</strong>: <span class="has-text-danger">{{$t('revoked')}}</span></span>
                      </td>
                    </tr>
                    <tr>
                      <td class="is-3">{{ $t('Order number') }}</td>
                      <td class="is-9">{{order}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('DN-name') }}</td>
                      <td>{{dnRSA}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Validity') }}</td>
                      <td>{{validFromRSA}} - {{validToRSA}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>
              <h4>{{ $t('Token you use') }}: <strong>{{devcheck}}</strong></h4>
              <br>
              <router-link to="/" class="button is-primary is-medium is-flex-mobile">{{ $t('Go back to the main page') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'KeyCheckResult',
  props: [
    'params'
  ],
  data(){
    return {
      devices: null
    }
  },
  computed: {
    revoked () {
      return this.params ? this.params.revoked : false
    },
    order () {
      return this.params ? this.params.order : null
    },
    dnGOST () {
      return this.params ? this.params.dnGOST : null
    },
    dnRSA () {
      return this.params ? this.params.dnRSA : null
    },
    devcheck () {
      var devch = this.params ? this.params.devcheck : null
      if(devch == "AladdinJaCarta2") return "JaCarta-2 PKI/ГОСТ"; else
      if(devch == "AladdinJaCartaGAMMA") return "JaCarta PKI/ГОСТ";else
      if(devch == "SafeNet 5110 with applet(GemCart)") return "SafeNet eToken 5110 (GC)";else
      if(devch == "SafeNetToken") return "SafeNet eToken 5110";else
      if(devch == "JaCarta2(Software)") return "JaCarta-2 PKI/ГОСТ";
    },

    validFromGOST () {
      return this.params ? this.$moment(this.params.validFromGOST.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')).format('DD/MM/YYYY, h:mm:ss Z') : null
    },
    validFromRSA () {
      return this.params ? this.$moment(this.params.validFromRSA.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')).format('DD/MM/YYYY, h:mm:ss Z') : null
    },
    validToGOST () {
      return this.params ? this.$moment(this.params.validToGOST.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')).format('DD/MM/YYYY, h:mm:ss Z') : null
    },
    validToRSA () {
      return this.params ? this.$moment(this.params.validToRSA.replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$2/$1')).format('DD/MM/YYYY, h:mm:ss Z') : null
    }
  }
}
</script>
<style scoped>
  .has-user-border {
    border: 1px solid #339968;
  }
</style>

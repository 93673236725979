<template>
  <div>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Checking ready for operation of the security token') }}</h1>
        <p class="has-text-grey-light">{{ $t('Make sure that the USB port of your computer is connected with only one security token') }}</p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6 is-paddingless">
              <div class="field">
                <label class="label">{{ $t('The country where your company is registered') }}*</label>
                <div class="select is-fullwidth">
                  <select name="country" v-model="selectedCountryId">
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                  </select>
                </div>
                <p class="help">{{ $t('If you are an individual or solo entrepreneur - specify you country of issue') }}</p>
              </div>
              <div style="height:0.75rem;"></div>
              <div class="field">
                <label class="label">{{ $t('Enter the password to access the security token') }}*</label>
                <div class="control">
                  <input v-model.trim="password" name="password" v-validate="'required'" class="input is-medium" :class="{'has-error': errors.has('password')}"  type="password" v-bind:placeholder="$t('Enter the password')">
                  <p v-if="errors.has('password')" class="alert-error ">{{errors.first('password')}}</p>
                </div>
                <p class="help">{{ $t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked') }}</p>
              </div>

              <div style="height: 2rem;"></div>

              <div class="buttons">
                <input class="button is-primary is-medium" type="button" v-on:click="GoToKeyCheckStepTwo" v-bind:value="$t('Check security token')">
                <input class="button is-medium right" type="button" v-on:click="GoToKeyCheckStepThree" v-bind:value="$t('Check password')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'KeyCheckStepOne',
  data () {
    return {
      countries: null,
      selectedCountryId: null,
      password: null
    }
  },
  watch: {
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            this.$http.get('/query/get-country/').then(response => {
              if (response.data) {
                const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                this.selectedCountryId = country.id
              } else {
                this.selectedCountryId = '2'
              }
            })
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    currentCountryDisabled () {
      const country = this.countries ? this.countries.find(item => item.id === this.selectedCountryId) : null
      return country.disabled
    }
  },
  methods: {
    GoToKeyCheckStepTwo () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('update:password', this.password)
          this.$emit('update:country', this.currentCountryDisabled)
          this.$emit('update:currentForm', 'FormStepTwo')
        }
      })
    },
    GoToKeyCheckStepThree () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('update:password', this.password)
          this.$emit('update:currentForm', 'FormStepThree')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .right {
    margin-left: 20px;
  }
</style>
